import React from 'react'
import styled from 'styled-components';
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';
import { VideoFrame } from '../../components/VideoFrame';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'

import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import IntegrationsGrid from '../images/integrationsGrid.svg'



const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | For Revenue Operations</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='wave'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            What Truly Can Do For You
          </PageTitle2>
          <RoleSelector>
            <a href="/solutions/for-revenue-ops">
              <Role>Revenue Operations</Role>
            </a>
            <a href="/solutions/for-analytics-teams">
              <ActiveRole>Analytics Teams</ActiveRole>
            </a>
            <a href="/solutions/for-sales-leaders">
              <Role>Sales Teams</Role>
            </a>
            <a href="/solutions/for-executives">
              <Role>Marketing Teams</Role>
            </a>
          </RoleSelector>

          <br/><br/>
          
          <Title3 as="h2" color={colors.accentFog} bold mt="3rem" mb="1.5rem">
              A Better Way To Process, Label and Transform Sales Data
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem" textAlign="left">
              Unlike sales tools which make it easier for reps to update CRM, Truly eliminates the need completely and then lets you operationalize this data at scale to drive better sales, marketing and customer success.               <br/><br/><br/>
              <b><u>Truly Capture</u></b> product (email, voice, web meetings, sms) automatically capture more activity data in salesforce than any competing product (68% more emails than sales engagement tools like Outreach, 33% more calls than call center software like LiveOps, 6% more web meetings than conversation intelligence tools like Gong)
              <br/><br/><br/><br/>
               <b><u>AI-Enabled Bots</u></b> enable you to automate low value tasks for managers, reps and ops teams.  Build sophisticated automation rules that combine your sales process, AI based data enrichment and advanced no-code logic.
              <br/><br/><br/>
              </Large>


              <VideoFrameContainer>
                <VideoFrame caption="Auto-Update Fields" background="Fields" videoLink="https://player.vimeo.com/video/626604726?h=76e0f3a870&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" />
              </VideoFrameContainer>
          
        </Box>
      </Section>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

const RoleSelector = styled.div`
  justify-content: center;
  border: white 2px solid;
  border-radius: 32pt;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-align: center;
  flex-grow: 0;
  width: 500px;
  margin: 0 auto;
`;

const VideoFrameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: center;
`

const Role = styled.div`
  color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  border-radius: 32pt;
  display: flex;
  flex-align: center;
  flex-grow: 1;
  justify-content: center;

  :hover{
    background-color: rgba(255,255,255,0.3);
    animation: background color 0.2s;
  }
`;

const ActiveRole = styled.div`
  color: black;
  font-weight: bold;
  border-radius: 32pt;
  background-color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;